/*****************Base********************/
@width-sm: 768px; //@grid-float-breakpoint;
@width-md: 992px; //@screen-md; 
@width-lg: 1600px; //1900px;
@nav-width: 200px;
@top-bar-height: 60px;

@base-url: '../..';
@base-img-url: '../img';
@variable-img-url: 'img';

html, body{
  height: 100%;
}
body{
  overflow-x: hidden;
  overflow-y: scroll;
  color: @font-color;
  &.no-scroll{
    overflow-y: hidden;
  }
}


a{
  color: @base-color;
  cursor: pointer;
  outline: none;
  &:hover, 
  &:focus,
  &:active{
    text-decoration: none;
    outline: none;
  }
}

.menu-head{
  display: none;
}

.pull-center{
  display: table;
  margin-left: auto !important;
  margin-right: auto !important;
}

.padding-null-left{
  padding-left: 0px;
  @media(max-width: @width-md){
    padding-left: 15px;
  }
}
.padding-null-right{
  padding-right: 0px;
  @media(max-width: @width-md){
    padding-left: 15px;
  }
}

.tooltip-inner{
  .border-radius(2px);
}

hr{
  margin-top: 5px;
  margin-bottom: 10px;
}


[class^="if-"]{
  font-size: 14px;
}

.headercase(){
  text-transform: uppercase;
  font-size: 1rem;
  font-family: @head-font;
  font-weight: normal;
  letter-spacing: 1.5px;
}

@media(min-width: @width-md){
  .no-gutter  [class^="col-"] {
   padding-right:0;
 }
}


.details_hide,
table td.details_hide,
table th.details_hide
{
  display:none;
}
.details_show{
  display:block;
}
.details_show-inline{
  display:inline-block;
}

.container_hide {
  display: none;
}
.container_show {
  display: block;
}

.show-table{
  display: table;
}

