#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

#banner-content {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  padding: 15px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
  p {
    margin: 0;
    font-size: 14px;
    color: #333;
    display: inline-block;
    vertical-align: middle;
  }
  a {
    color: @base-color;
    text-decoration: underline;
  }
  button {
    margin-left: 15px;
    vertical-align: middle;
  }
}