.btn-radius{
	.border-radius(2px);
}

.input-radius{
}

.action-btn-radius{
	.border-radius(2px);
}

.yes-no-radius{
	 .border-radius(0);
}

.ui-dialog-radius{
	.border-radius(2px);
}
