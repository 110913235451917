﻿
.cookie-pop-overlay {
    display: none;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    &.active {
        display: block;
    }
}
.cookie-pop {
    @width: 450px;
    @height: 220px;
    padding: 30px;
    background: #fff;
    color: @font-color;
    width: @width;
    height: @height;
    box-sizing: border-box;
    position: fixed;
    top: ~"calc(50% - @height/2)";
    left: ~"calc(50% - @width/2)";
    z-index: 999;


    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .cookie-text {
        margin-right: 150px;
        color: contrast(@sec-color);
    }

    .cookie-detail {
        height: @height - 90px;
        margin-bottom: 10px;
        overflow-y: auto;
    }

    .close-btn {
        @height: 26px;
        height: @height;
        line-height: @height;
        width: @height;
        text-align: center;
        color: #fff;
        background: @danger-color;
        .border-radius(@height);
        cursor: pointer;
        position: absolute;
        top: -10px;
        right: -10px;

        &:hover {
            background: darken(@danger-color, 5%);
        }
    }

    h4, h3 {
        margin: 0px 0px 15px;
        line-height: 1.3;
    }

    a,
    a:focus {
        color: @base-color;
    }

    a:hover {
        color: darken(@base-color, 10%);
    }

    .btn-primary:hover {
        background: darken(@base-color, 10%);
    }

    .cookie-link {
        list-style: none;
        margin: 5px 0 0;
        padding: 0;
        font-size: 1.2rem;

        li {
            display: inline-block;
            margin-right: 15px;
        }
    }
}
