.btn{
	font-weight: bold;
	font-family: @head-font;
	font-size: @font-size-base;
	padding: 0px 10px;
	height: 26px;
	line-height: 26px;
	margin-right: 8px;
	.btn-radius;
	&:last-child{
		margin-right: 0px;
	}
	&:focus{
		color: #fff;
		.box-shadow(0 0 8px fade(@base-color, 80%));
	}

	[class^="if-"], [class^="fa"], .glyphicon{
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		&.right{
			margin-left: 5px;
			margin-right: 0px;
		}
		&.center{
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	&:active, &:hover, &.active{
		outline: none;
	}
	&.btn-dark{
		background: @base-dark;
		color: #ffffff;
		&:hover{
			background: darken(@base-dark, 10%);
		}
	}
	&.btn-default{
		background: #999;
		border: none;
		color: #fff;
		&:hover{
			background: #444;
			border: none;
		}
		&:focus{
			.box-shadow(0 0 8px fade(@base-color, 80%));
		}
	}
	&.btn-primary{
		background: @base-color;
		border: none;
		color: #fff;
		&:hover{
			background: darken(@base-color, 20%);
			border: none;
		}
		&:focus{
			.box-shadow(0 0 8px rgba(0,0,0,0.4));
		}
	}
	&.btn-sm{
		height: 20px;
		line-height: 16px;
		padding: 2px 5px;
		font-size: .8em;
		[class^="if-"]{
			font-size: 14px;
			margin-right: 0px;
			margin-left: 0px;
			margin-top: 0px;
		}
	}
	&.loader{
		&.loading{
			position: relative;
			padding-right: 30px;
			transition: all .5s linear;
			&:after{
				content: '';
				background: url('@{base-url}/img/ajax-loader.gif') no-repeat center center;
				width: 24px;
				height: 24px;
				position: absolute;
				top: 0px;
				right: 2px;
			}
		}
	}

}


a.btn{
	&:extend(.btn);
	line-height: 26px;
}
.btn-group{
	label{
		margin-top: 0px;
	}
	.btn{
		margin-right: 0px;
		.border-radius(30px);
		&.active{
			.box-shadow(~'inset 0px 0px 8px rgab(0,0,0 .9)');
		}
	}
}


.action-div{
	background: fade(@base-dark, 5%);
	border-top: 1px solid fade(@base-dark, 6%);
	padding: 10px 10px 10px;
	margin: 15px -9px -9px;
}
.action-list{
	padding: 0;
	margin-bottom: 0px;
	list-style: none;
	& > li{
		display: inline-block;
		&:last-child{
			.btn{
				margin-right: 0px;
			}
		}
	}
}
