.cookie-popup{
  @height: 200px;
  @width: 500px;
  width: @width;
  padding: 10px 20px 20px;

  background: fade(@base-color, 30%);

  position: fixed;
  bottom: 0;
  left: 0;
  .cookie-link{
    list-style: none;
    margin: 10px 0 0;
    padding: 0;
    li{
      display: inline-block;
      margin-right: 30px;
    }
  }
}