#banner-content {
  position: fixed;
  bottom: 20%;
  left: 25%;
  width: 50%;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
  .banner-header {
    background-color: #f8f9fa;

    p {
      margin: 0;
      padding: 25px;
      font-size: 14px;
      color: #333;
      display: inline-block;
      text-align: justify;
    }

    .heading {
      padding: 0 25px;
      font-size: 24px;
      font-weight: 400;
      margin: 2% 0 0 0;
    }
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    margin: 1% 0;

    .btn:not(.btn-dark){
      &:focus{
        color: #0a0a0a;
      }
    }

    .save-btn {
      height: 50px;
      width: 100%;
      text-transform: uppercase;
    }
  }

  .toggle {
    display: flex;
    justify-content: space-around;
    height: 100px;
    margin-top: 2%;

    .border {
      border-left: 1px solid;
      color: #dcdde1;
      height: 100%;
    }
  }
  .toggle-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2%;
    width: 100%;
    background-color: #f8f9fa;
    width: 100%;

    label {
      margin: 0;
      font-size: 14px;
      color: #333;
      letter-spacing: 0.5px;
      font-weight: 300;
    }
  }

  .bottom-border {
    border-top: 1px solid;
    width: 100%;
    color: #dcdde1;
  }
}

.switch-wrapper {
  position: relative;
  width: 50px;
  height: 25px;

  input[type="checkbox"] {
    appearance: none;
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;

    &::before {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 19px;
      height: 19px;
      background-color: #fff;
      border-radius: 50%;
      outline: none;
      transition: transform 0.3s ease;
    }

    &:checked {
      background-color: @base-color;
      outline: none;
    }

    &:checked::before {
      transform: translateX(25px);
      outline: none;
    }

    &:disabled {
      background-color: @base-lightest;
      cursor: not-allowed;
    }
  }
}
